import React from 'react'
import bomblogo from '../assets/bomblogo.png';


const Logo = () => {
    return (
        <div>
            <img src={bomblogo} width="240" />
        </div>
    )
}


export default Logo;